import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
// import channelImage from '../assets/slides/channel/main.jpg';
import transformationMainImg from '../assets/slides/transformation/main.jpg';
import transformationMarketImg from '../assets/slides/transformation/market.jpg';
import transformationBrochuresImg from '../assets/slides/transformation/brochures.jpg';
import transformationEcommerceImg from '../assets/slides/transformation/ecommerce.jpg';
import transformationConsumerImg from '../assets/slides/transformation/consumer.jpg';
import salesManagerImg from '../assets/slides/expectations/SatisYoneticileri.jpg';
import salesDirectorImg from '../assets/slides/expectations/SatisDirektoru.jpg';
import commercialMarketingImg from '../assets/slides/expectations/TicariPazarlama.jpg';
import budgetPlanningImg from '../assets/slides/expectations/ButcePlanlama.jpg';
import regionManagersImg from '../assets/slides/expectations/BolgeYoneticileri.jpg';
import demandPlanningImg from '../assets/slides/expectations/TalepPlanlamasi.jpg';
import accountingImg from '../assets/slides/expectations/Muhasebe.jpg';
import fieldSalesImg from '../assets/slides/expectations/SahaSatisTemsilcileri.jpg';
import problem1Img from '../assets/slides/problems/1.jpg';
import problem2Img from '../assets/slides/problems/2.jpg';
import problem3Img from '../assets/slides/problems/3.jpg';
import problem4Img from '../assets/slides/problems/4.jpg';
import problem5Img from '../assets/slides/problems/5.jpg';
import problem6Img from '../assets/slides/problems/6.jpg';
import competitorImg from '../assets/slides/management/competitor.jpg';
import consumerImg from '../assets/slides/management/consumer.jpg';
import activityImg from '../assets/slides/management/activity.jpg';
import budgetImg from '../assets/slides/management/budget.jpg';
import paymentsImg from '../assets/slides/management/payments.jpg';
import contractsImg from '../assets/slides/management/contracts.jpg';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    {
      line1: "Perakende aktivitelerinizi",
      line2: "optimize edin,",
      line3: "OCRION ile geleceği değiştirin"
    },
    {
      line1: "Kolay kullanım ve",
      line2: "zengin içerik ile",
      line3: "8-12 haftada kullanıma hazır"
    },
    {
      line1: "Rakip fiyat ve aktiviteleri",
      line2: "takip edin,",
      line3: "hedeflerinize hızlı ulaşın"
    }
  ];

  useEffect(() => {
    // 5 saniyede bir otomatik geçiş
    const timer = setInterval(() => {
      setCurrentSlide((prev) => 
        prev === slides.length - 1 ? 0 : prev + 1
      );
    }, 5000);

    // Component unmount olduğunda timer'ı temizle
    return () => clearInterval(timer);
  }, []);

  // Add console log to check if slides are rendering
  console.log('Current slide:', currentSlide);

  return (
    <div className="home-page">
      <section className="hero">
        <div className="hero-content">
          <div className="slide-container">
            {slides.map((slide, index) => (
              <div 
                key={index} 
                className={`slide ${index === currentSlide ? 'active' : ''}`}
              >
                <div className="hero-text">
                  <h1>
                    <div className="line">{slide.line1}</div>
                    <div className="line">{slide.line2}</div>
                    <div className="line highlight">{slide.line3}</div>
                  </h1>
                </div>
              </div>
            ))}
          </div>
          
          {/* Sadece nokta navigasyonu */}
          <div className="slide-dots">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="value-proposition">
        <div className="container">
          <div className="value-content">
            <div className="value-left">
              <h2>
                <div className="title-line">Gerçek Zamanlı Rakip Analizi,</div>
                <div className="title-line">Akıllı Bütçe Yönetimi,</div>
                <div className="title-line">Güçlü Satış Performansı!</div>
              </h2>
            </div>
            <div className="value-right">
              <p>
                Doğru Kanal ve SKU'yu, Doğru Fiyat ile Doğru Zamanlamayla önererek perakende aktiviteleriniz için yeni nesil çözüm sunuyoruz
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="management">
        <div className="container">
          <h2>En Yeni Teknoloji ile Perakende Aktivitelerinizi Uçtan Uca Yönetin</h2>
          <div className="management-grid">
            <div className="management-item">
              <div className="management-number">1</div>
              <div className="management-image">
                <img src={competitorImg} alt="Rakip Fiyat ve Aktiviteleri" />
              </div>
              <h3 className="highlighted-title">Rakip Fiyat ve Aktiviteleri</h3>
              <p>Market broşürleri, e-ticaret siteleri ve sahadan alınan, hem sizin hem de rakiplerin fiyat ve aktiviteleri</p>
            </div>
            
            <div className="management-item">
              <div className="management-number">2</div>
              <div className="management-image">
                <img src={consumerImg} alt="Tüketici Pazarlaması" />
              </div>
              <h3 className="highlighted-title">Tüketici Pazarlaması</h3>
              <p>Son tüketiciye yönelik sadakat programları, tüketici harcamalarına göre ödüllendirme, dijital cüzdanlarına para aktarımı</p>
            </div>
            
            <div className="management-item">
              <div className="management-number">3</div>
              <div className="management-image">
                <img src={activityImg} alt="Aktivite Planlama" />
              </div>
              <h3 className="highlighted-title">Aktivite Planlama</h3>
              <p>Aktivite planının tanımlandığı, bütçe kontrolü ve onayı sonrasında yayımlandığı, AI destekli Aktivite Öneri uygulaması</p>
            </div>
            
            <div className="management-item">
              <div className="management-number">4</div>
              <div className="management-image">
                <img src={budgetImg} alt="Harcama Bütçesi" />
              </div>
              <h3 className="highlighted-title">Harcama Bütçesi</h3>
              <p>Kanal, kategori, aktivite türü (insert, geri dönüş, fatura altı vb.) seviyelerinde takibini ve kontrolünü yapabildiğiniz</p>
            </div>
            
            <div className="management-item">
              <div className="management-number">5</div>
              <div className="management-image">
                <img src={paymentsImg} alt="Hakedişler" />
              </div>
              <h3 className="highlighted-title">Hakedişler</h3>
              <p>Promosyon aktiviteleriyle ilişkili hızlı ve doğru fatura girişi. Maliyetin doğru ürüne yansıtılması, ürün karşılığının daha doğru hesaplanması</p>
            </div>
            
            <div className="management-item">
              <div className="management-number">6</div>
              <div className="management-image">
                <img src={contractsImg} alt="Yıllık Sözleşmeler" />
              </div>
              <h3 className="highlighted-title">Yıllık Sözleşmeler</h3>
              <p>Yıllık sözleşmelerinizi, oranları, sözleşme karşılığında teşhir sayısı, insert sayılarınızı yönetin</p>
            </div>
          </div>
        </div>
      </section>

      <section className="transformation">
        <div className="container">
          <h2>Tüketim Ürünleri Sektöründe Değişim Zamanı</h2>
          <div className="transformation-content">
            <div className="transformation-text">
              <h3>Market verisini avantaja çevirin; hızlı ve etkili kararlarla öne çıkın!</h3>
              <p>Gen-AI destekli platformumuz ile Fırsatları erken tespit ederek bütçe kaynaklarınızı en etkili şekilde kullanmanızı ve sonucunda satış hedeflerinize daha hızlı ulaşmanızı sağlıyoruz</p>
            </div>
            <div className="transformation-features">
              <div className="service-grid">
                <div className="service-box">
                  <div className="service-icon">
                    <img src={transformationBrochuresImg} alt="Mağaza Broşürleri" />
                  </div>
                  <h3 className="highlighted-title">Mağaza Broşürleri</h3>
                  <p>Geçmiş dönem tüm mağaza broşürleri, yayımlanmış önümüzdeki dönem rakip fiyat ve aktiviteleri</p>
                </div>
                
                <div className="service-box">
                  <div className="service-icon">
                    <img src={transformationEcommerceImg} alt="E-Ticaret Siteleri" />
                  </div>
                  <h3 className="highlighted-title">E-Ticaret Siteleri</h3>
                  <p>E-ticaret kanallarında rakip fiyat ve aktiviteleri</p>
                </div>
                
                <div className="service-box">
                  <div className="service-icon">
                    <img src={transformationConsumerImg} alt="Tüketici Satış Verileri" />
                  </div>
                  <h3 className="highlighted-title">Tüketici Satış Verileri</h3>
                  <p>Mağazalarda alış-veriş yapan son tüketici fiyat verileri</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section">
        <div className="container">
          <div className="video-wrapper">
            <iframe
              src="https://player.vimeo.com/video/566312187?h=c1cc0c8f38"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Ocrion Video"
            ></iframe>
          </div>
        </div>
      </section>

      <section className="why-different">
        <div className="container">
          {/* Features sayfasından taşınan içerik */}
        </div>
      </section>

      <section className="demo-section">
        {/* Demo form bölümünü de silelim */}
      </section>
    </div>
  );
};

export default Home; 